import VanillaToasts from 'vanillatoasts'

function autoClickForm(form){
  console.log('clicked', form)
  for (var i = 0; i < form.length; i++) {
    if (form[i].name == "commit"){
      form[i].click()
    }
  }
}

function setupAutosave(form){
  var inputs = form.querySelectorAll('input');
  var selects = form.querySelectorAll('select');
  var texts = form.querySelectorAll('textarea');

  inputs.forEach(function(inputElm){
    inputAutosave(inputElm)
  })

  texts.forEach(function(inputElm){
    inputAutosave(inputElm)
  })

  selects.forEach(function(select){
    select.addEventListener('change', function(event){
      autoClickForm(select.form)
    })
  })
}

function inputAutosave(inputElm){
  inputElm.addEventListener('input', function(event){
    var duration = 1000;
    clearTimeout(inputElm._timer);
    inputElm._timer = setTimeout(function(){
      autoClickForm(inputElm.form)
    }, duration);
  });
}

function setupToast(form){
  form.addEventListener('ajax:success', function(){
    VanillaToasts.create({
      title: 'AMOOV',
      text: 'Information Saved',
      type: 'success', // success, info, warning, error   / optional parameter
      icon: '/logo.png', // optional parameter
      timeout: 1800 // hide after 5000ms, // optional paremter
      // callback: function() { ... } // executed when toast is clicked / optional parameter
    });
  });
}

function setupRedirect(form){
  form.addEventListener('ajax:complete', function(event){
    if (form.getAttribute('redirect') == null){
      Turbolinks.visit(window.location.href)
    } else {
      Turbolinks.visit(form.getAttribute('redirect'))
    }
  });
}

function initForms(){
  document.querySelectorAll('form').forEach(function(form){
    setupToast(form);
    if (form.classList.contains('autosave')){
      setupAutosave(form)
    } else {
      setupRedirect(form)
    }
  })
}


document.addEventListener("turbolinks:load", function() {
  console.log("initializing forms")
  if (document.getElementById('vanillatoasts-container') == null){
    var container = document.createElement('div');
    container.id = 'vanillatoasts-container';
    document.body.appendChild(container);
  }

  initForms()

  var remote_links = document.querySelectorAll('a[data-remote=true]');

  remote_links.forEach(function(inputElm){
    inputElm.addEventListener('ajax:complete', function(event){
      Turbolinks.visit(window.location.href)
    });
  })
})

function addFields(params={}, association, content){
  postData(params.url, params.data).then(res=>{
    console.log(res)
    var new_id = new Date().getTime();
    var id = res.object.id
    var regexp = new RegExp("new_" + association, "g")
    var container = document.getElementById('fieldset');
    var section = document.createElement('div');
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = params.id.replace(regexp, new_id);
    input.value = id;
    container.appendChild(section);
    container.appendChild(input);
    section.innerHTML = content.replace(regexp, new_id);

    if (container.closest('form').classList.contains("autosave")){
      console.log("TEST")
      var new_inputs = section.querySelectorAll('input');
      new_inputs.forEach(function(inputElm){
        inputAutosave(inputElm)
      })
    }
  })
}


async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'same-origin', // no-cors, *cors, same-origin
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}

function removeItem(target, parentElement=null){
  // move[crates_attributes][1][id]
  // move[crates_attributes][1][_destroy]
  var section = parentElement || target.parentElement;
  var input = target.querySelector('input')
  var id_input_name = input.name.replace('_destroy', 'id')
  var id_input = document.querySelector(`input[name="${id_input_name}"]`)

  input.value = true;
  section.classList.add = "hidden";
  input.onchange();

  id_input.remove();
  section.remove();
}

export default {addFields, removeItem, autoClickForm}
