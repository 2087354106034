// Importing Tailwinds CSS library
import '../css/application.css'

// Requiring dependencies
import Rails from '@rails/ujs';
import Turbolinks from "turbolinks";
Rails.start();
Turbolinks.start()
require("@rails/activestorage").start()
require("channels")
require("packs/forms")

import List from "./list"
window.List = List
// Import JS from packs

import f from './forms'
window.addFields = f.addFields
window.removeItem = f.removeItem
window.autoClickForm = f.autoClickForm

import Sortable from 'sortablejs';
railsSortable = ()=>{
  document.querySelectorAll('.sortable').forEach(el=>{
    new Sortable(el, {
      animation: 150,
      ghostClass: 'bg-blue-normal',
      onEnd: function(event){
        let ids = Array.from(el.children).map(e=>{return e.id});
        let data = {rails_sortable: ids}
        Rails.ajax({
          type: "POST",
          url: "/sortable/reorder",
          data: new URLSearchParams(data).toString()
        })
      }
    });
  })
}

window.railsSortable = railsSortable

document.addEventListener("turbolinks:load", function() {
  railsSortable()
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
